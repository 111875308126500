

<template>
  <div class="Attend">
    <div class="tab_Top">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect">
        <el-menu-item index="1">考勤组设置</el-menu-item>
        <el-menu-item index="2">班次设置</el-menu-item>
        <el-menu-item index="3">假期设置</el-menu-item>
        <el-menu-item index="4">考勤数据</el-menu-item>
      </el-menu>
      <div class="Men_1">
        说明：设置考勤部门与人员、考勤班次、考勤方式（Wi-Fi）等相关规则，点击下方添加考勤进入按步骤设置即可。
      </div>
    </div>
    <div class="Tables">
       <div class="Tab_T" @click="Xinjian()" v-if="TextMoMo('attendance:group:add')"><img class="Img_ti" src="../../assets/img/+++.png" alt="">添加考勤组</div>
        <div class="el_seso"><i class="el-icon-search" @click="inpu_sech"></i></div>
        <div class="dsds">
          <el-input v-model="input_seach" placeholder="请输入内容" @keyup.enter.native="inpu_sech()"></el-input>
        </div>
        <div>
          <el-table
            :data="tableData"
            class="table"
            ref="multipleTable"
            header-cell-class-name="table-header"
            @selection-change="handhange">
            <el-table-column
              type="selection"
              width="55"
              align="center"></el-table-column>
            <el-table-column
              prop="id"
              label="ID"
              width="65"
              align="center"></el-table-column>
            <el-table-column align="center" prop="name" label="考勤组名称" width="240"></el-table-column>
            <el-table-column align="center" prop="leader.realname" label="考勤负责人" width="150"></el-table-column>
            <el-table-column align="center" prop="type_text" label="班次" width="180"></el-table-column>
            <el-table-column align="center" prop="createtime" label="考勤时间" width="180"></el-table-column>
            <el-table-column align="center" prop="person_num" label="考勤人数" width="180"></el-table-column>
            <el-table-column align="center" prop="mode" label="考勤方式" width="180"></el-table-column>
            <el-table-column label="操作" align="center" >
              <template #default="scope" >
                  <div class="el_butto" >
                    <el-button type="text" @click="Handlbchak(2, scope.row)" v-if="TextMoMo('attendance:group:read')">查看</el-button>
                    <el-button type="text" v-if="!TextMoMo('attendance:group:read')">查看</el-button>
                  </div>
                  <div class="el_butto" >
                    <el-button type="text" @click="Handlbchak(1, scope.row)" v-if="TextMoMo('attendance:group:edit')">编辑</el-button>
                  </div>
                <div class="el_butto rere" >
                    <el-button
                    type="text" v-if="TextMoMo('attendance:group:del')"
                    class="red"
                    @click="HanDel(scope.row)"
                    >删除</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination
              background
              @current-change="handhange1"
              :current-page="currentPage1"
              :page-size="page.pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total1">
            </el-pagination>
          </div>
        </div>

    </div>
    <div ref="rightPanel" v-if="show1" class="rightPanel-container show">
          <div class="rightPanel-background" />
          <div style="width: 790px;position: fixed;top: 0;right: 0;z-index:999;" >
            <el-scrollbar style="height:100%">
              <div class="rightPanel" style="height: 690px;padding-bottom: 270px;">
                <div class="Rig_1" v-if="XX == 1">添加考勤组<i class="el-icon-close" @click="Close" style="float:right;padding:20px 50px;cursor: pointer;"></i></div>
                <div class="Rig_1" v-if="XX == 2">编辑考勤组<i class="el-icon-close" @click="Close" style="float:right;padding:20px 50px;cursor: pointer;"></i></div>
                <div class="Rig_A">
                  <div style="overflow:hidden">
                      <div style="width:50%;float:left">
                          <div class="Rig_D">
                              <div class="Rig_Ddd" style="margin-bottom:12px">考勤组名称：</div>
                              <div class="Rtty">
                                <el-input v-model="List_G.name" placeholder="请输入考勤组名称，比如：行政部门考勤" class="El_ii"></el-input>
                              </div>
                          </div>
                      </div>
                      <div style="width:50%;float:left">
                          <div class="Rig_D">
                              <div class="Rig_Ddd" style="margin-bottom:12px">考勤负责人：</div>
                              <el-select v-model="List_G.leader_id" placeholder="请选择考勤负责人">
                                  <el-option v-for="item in StaFFList" :key="item.id" :label="item.realname" :value="item.id"></el-option>
                              </el-select>
                          </div>
                      </div>
                  </div>
                  <div style="overflow:hidden">
                      <div style="width:100%;float:left">
                          <div class="Rig_D Fiooi">
                              <div class="Rig_Ddd" style="margin-bottom:9px">白名单：</div>
                              <el-select v-model="value1" placeholder="请选择白名单">
                                  <el-option v-for="item in StaFFList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                              </el-select>
                          </div>
                      </div>

                  </div>
                  <div style="overflow:hidden">
                      <div style="width:100%;float:left">
                          <div class="Rig_D Fiooi">
                              <div class="Rig_Ddd" style="margin-bottom:9px">考勤部门：</div>
                              <el-select v-model="List_ids" multiple placeholder="请选择考勤部门">
                                  <el-option v-for="item in DMent" :key="item.id" :label="item.name" :value="item.id"></el-option>
                              </el-select>
                          </div>
                      </div>
                  </div>
                  <div style="overflow:hidden">
                      <div style="width:100%;float:left">
                          <div class="Rig_D Fiooi">
                              <div class="Rig_Ddd" style="margin-bottom:9px">考勤人员：</div>
                              <el-select v-model="Litaffids" multiple placeholder="请选择考勤人员">
                                <el-option v-for="item in StaFFList" :key="item.id" :label="item.realname" :value="item.id"></el-option>
                              </el-select>
                          </div>
                      </div>
                  </div>
                    <div class="Rig_D">
                      <div class="Rig_Ddd">考试类型：</div>
                      <div style="float:left;margin-top:10px">
                          <el-radio v-model="List_G.type" label="fixed">固定班制（每天考勤时间一样）</el-radio>
                          <el-radio v-model="List_G.type" label="class">排班制（自定义设置考勤时间）</el-radio>
                          <el-radio v-model="List_G.type" label="free">自由工时（不设置班次，随时打卡）</el-radio>
                      </div>
                    </div>
                    <div class="Rig_D">
                      <div class="Rig_Ddd" style="margin-bottom:10px">工作日设置：</div>
                      <div class="Riot">
                        <div class="rrt">快捷设置班次</div>
                        <div class="rrt" style="margin-left:20px"> A班：09:00-18:00 19:00-22:00 23:00-次日02:00</div>
                        <div class="rrt" style="margin-left:18px;color: #0081FF;">换班次</div>
                      </div>
                    </div> 
                    <div class="Rig_D" style="width: 98%;">
                      <el-table ref="multipleTable" :data="tableData2" tooltip-effect="dark" :header-cell-style="{background:'#FAFAFA'}"
                            style="width: 100%" @selection-change="handleSelectionChange" show-overflow-tooltip="false">
                        <el-table-column type="selection" width="43" show-overflow-tooltip="false" > </el-table-column>
                        <el-table-column prop="date" label="工作日" width="170"></el-table-column>
                        <el-table-column prop="daka" label="班次时间段" width="370"></el-table-column>
                        <el-table-column label="操作" width="140">
                          <template>
                            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">更换班次</el-button>
                          </template>
                        </el-table-column>
                      </el-table> 
                    </div>    
                    <div class="Rig_D">
                      <div class="Rig_Ddd">节假日安排自动排休： </div>
                      <div>
                            <el-radio v-model="List_G.holiday_on" :label=1>启用</el-radio>
                            <el-radio v-model="List_G.holiday_on" :label=0>关闭</el-radio>
                      </div>
                    </div>   
                    <div class="Rig_D">
                      <div class="Rig_Ddd">打卡设置： </div>
                      <div>
                          <el-checkbox v-model="List_G.location_on" :true-label="1" :false-label="0">位置打卡</el-checkbox>
                          <el-checkbox v-model="List_G.wifi_on" :true-label="1" :false-label="0">wifi打卡</el-checkbox>
                      </div>
                      <div></div>
                    </div>     
                    <div class="Rig_D" style="width: 97%;"> 
                      <div class="Rig_y" style="float:left;margin-top:4px;margin-bottom:20px">
                        <div style="cursor: pointer;" @click="Tjwei(1)">添加位置</div>
                        <div>可添加多个办公地点</div>
                      </div>
                      <div style="">
                        <el-table ref="multipleTable" :data="LocationList" tooltip-effect="dark" :header-cell-style="{background:'#FAFAFA'}"
                              style="width: 100%" @selection-change="handleSelectionChange" show-overflow-tooltip="false">
                          <el-table-column prop="name" label="位置名称" width="190"></el-table-column>
                          <el-table-column prop="address" label="地址" width="360"></el-table-column>
                          <el-table-column label="操作">
                            <template #default="scope">
                              <el-button size="mini" style="background: #FF524D;margin-left: 10px;color:#fff" @click="handl_Del(1,scope.$index, scope.row)">删除</el-button>
                            </template>
                          </el-table-column>
                        </el-table> 
                      </div>
                      <div class="Rig_y" style="float:left;margin-top:4px;margin:20px 0">
                        <div style="cursor: pointer;" @click="Tjwei(2)">添加Wi-Fi</div>
                        <div>可添加多个办公Wi-Fi</div>
                      </div>                    
                      <div style="">
                        <el-table ref="multipleTable" :data="WifiList" style="width: 100%">
                          <el-table-column prop="ssid" label="wifi名称" width="190"></el-table-column>
                          <el-table-column prop="bssid" label="Mac" width="360"></el-table-column>
                          <el-table-column label="操作">
                            <template #default="scope">
                              <el-button style="background: #FF524D;margin-left: 10px;color:#fff" @click="handl_Del(2, scope.$index, scope.row)">删除</el-button>
                            </template>
                          </el-table-column>
                        </el-table> 
                      </div> 
                    </div> 
                    <div class="Rig_D">
                      <div class="Rig_Ddd" style="float:none">补卡申请： </div>
                      <div style="margin-top:10px;float:left">
                          <el-checkbox v-model="List_G.reissue_on" :true-label="1" :false-label="0">允许补卡申请</el-checkbox>
                      </div>
                      <div style="float:left;margin-top:12px;font-size: 14px; color: #8C8C8C;margin-left:18px">员工异常打卡时可提交申请，审批通过后修正异常</div>
                    </div>  
                    <div style="overflow:hidden" v-if="List_G.reissue_on == '1'">
                      <div style="width:50%;float:left">
                        <div class="Rig_D">
                          <div class="Rig_Ddd" style="margin-bottom:12px">补卡次数：</div>
                          <div class="Rtty">
                                <el-input v-model="List_G.reissue_num" placeholder="请选择每月最多允许补卡次数" class="El_ii"></el-input>
                          </div>
                        </div> 
                      </div>
                      <div style="width:50%;float:left">
                        <div class="Rig_D">
                          <div class="Rig_Ddd" style="margin-bottom:12px">补卡有效期：</div>
                          <div class="Rtty">
                                <el-input v-model="List_G.reissue_time" placeholder="请选择补卡有效期" class="El_ii"></el-input>
                          </div>
                        </div> 
                      </div>
                    </div>
                    <div class="Rig_D">
                      <div class="Rig_Ddd" style="float:none">更多设置 </div>
                      <div style="margin-top:10px">
                        <el-checkbox v-model="List_G.outside_on" label="允许外勤打卡" :true-label=1 :false-label=0></el-checkbox>
                        <el-checkbox v-model="List_G.outside_photo_on" label="外勤打卡需拍照" :true-label="1" :false-label="0"></el-checkbox>
                        <el-checkbox v-model="List_G.photo_on" label="开启拍照打卡" :true-label="1" :false-label="0"></el-checkbox>
                        <el-checkbox v-model="List_G.photo_album_on" label="不允许使用相册照片" :true-label="1" :false-label="0"></el-checkbox>
                      </div>
                    </div>  
                    <div class="BCSZ" style="margin-right:24px" v-if="XX == 1" @click="BTN_tj">确认添加</div>
                    <div class="BCSZ" style="margin-right:24px" v-if="XX == 2" @click="BTN_tj2">保存设置</div>
                    <div class="BCSZ" style="background:#fff;color:#595959;border: 1px solid #D9D9D9;">取消</div>
                </div>
              </div>
            </el-scrollbar>
          </div>
    </div>

    <div ref="rightPanel" v-if="show11" class="rightPanel-container show">
          <div class="rightPanel-background" />
          <div style="width: 790px;position: fixed;top: 0;right: 0;z-index:999;" >
            <el-scrollbar style="height:100%">
              <div class="rightPanel" style="height: 690px;padding-bottom: 270px;">
                <div class="Rig_1">查看考勤组<i class="el-icon-close" @click="Close" style="float:right;padding:20px 50px;cursor: pointer;"></i></div>
                
                <div class="Rig_A">
                  <div style="overflow:hidden">
                      <div style="width:50%;float:left">
                          <div class="Rig_D">
                              <div class="Rig_Ddd" style="margin-bottom:8px">考勤组名称：</div>
                              <div class="Rtty">
                                <el-input v-model="List_G.name" disabled placeholder="请输入考勤组名称，比如：行政部门考勤" class="El_ii"></el-input>
                              </div>
                          </div>
                      </div>
                      <div style="width:50%;float:left">
                          <div class="Rig_D">
                              <div class="Rig_Ddd" style="margin-bottom:12px">考勤负责人：</div>
                              <el-select v-model="List_G.leader_id" placeholder="请选择考勤负责人" disabled>
                                  <el-option v-for="item in StaFFList" :key="item.id" :label="item.realname" :value="item.id" ></el-option>
                              </el-select>
                          </div>
                      </div>
                  </div>
                  <div style="overflow:hidden">
                      <div style="width:100%;float:left">
                          <div class="Rig_D Fiooi">
                              <div class="Rig_Ddd" style="margin-bottom:9px">白名单：</div>
                              <el-select v-model="value1" placeholder="请选择白名单" disabled>
                                  <el-option v-for="item in StaFFList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                              </el-select>
                          </div>
                      </div>

                  </div>
                  <div style="overflow:hidden">
                      <div style="width:100%;float:left">
                          <div class="Rig_D Fiooi">
                              <div class="Rig_Ddd" style="margin-bottom:9px">考勤部门：</div>
                              <el-select v-model="List_ids" multiple placeholder="请选择考勤部门" disabled>
                                  <el-option v-for="item in DMent" :key="item.id" :label="item.name" :value="item.id"></el-option>
                              </el-select>
                          </div>
                      </div>
                  </div>
                  <div style="overflow:hidden">
                      <div style="width:100%;float:left">
                          <div class="Rig_D Fiooi">
                              <div class="Rig_Ddd" style="margin-bottom:9px">考勤人员：</div>
                              <el-select v-model="Litaffids" multiple placeholder="请选择考勤人员" disabled>
                                <el-option v-for="item in StaFFList" :key="item.id" :label="item.realname" :value="item.id"></el-option>
                              </el-select>
                          </div>
                      </div>
                  </div>
                    <div class="Rig_D">
                      <div class="Rig_Ddd">考试类型：</div>
                      <div style="float:left;margin-top:10px">
                          <el-radio v-model="List_G.type" label="fixed" disabled>固定班制（每天考勤时间一样）</el-radio>
                          <el-radio v-model="List_G.type" label="class" disabled>排班制（自定义设置考勤时间）</el-radio>
                          <el-radio v-model="List_G.type" label="free" disabled>自由工时（不设置班次，随时打卡）</el-radio>
                      </div>
                    </div>
                    <div class="Rig_D">
                      <div class="Rig_Ddd" style="margin-bottom:10px">工作日设置：</div>
                      <div class="Riot">
                        <div class="rrt">快捷设置班次</div>
                        <div class="rrt" style="margin-left:20px"> A班：09:00-18:00 19:00-22:00 23:00-次日02:00</div>
                        <div class="rrt" style="margin-left:18px;color: #0081FF;">换班次</div>
                      </div>
                    </div> 
                    <div class="Rig_D" style="width:98%">
                      <el-table ref="multipleTable" :data="tableData2" tooltip-effect="dark" :header-cell-style="{background:'#FAFAFA'}"
                            style="width: 100%" @selection-change="handleSelectionChange" show-overflow-tooltip="false">
                        <el-table-column type="selection" width="43" show-overflow-tooltip="false" > </el-table-column>
                        <el-table-column prop="date" label="工作日" width="170"></el-table-column>
                        <el-table-column prop="daka" label="班次时间段" width="370"></el-table-column>
                        <el-table-column label="操作" width="140">
                          <template>
                            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)" disabled>更换班次</el-button>
                          </template>
                        </el-table-column>
                      </el-table> 
                    </div>    
                    <div class="Rig_D">
                      <div class="Rig_Ddd">节假日安排自动排休： </div>
                      <div>
                        <el-radio v-model="List_G.holiday_on" :label=1 disabled>启用</el-radio>
                        <el-radio v-model="List_G.holiday_on" :label=0 disabled>关闭</el-radio>
                      </div>
                    </div>   
                    <div class="Rig_D">
                      <div class="Rig_Ddd">打卡设置： </div>
                      <div>
                          <el-checkbox v-model="List_G.location_on" :true-label="1" :false-label="0" disabled>位置打卡</el-checkbox>
                          <el-checkbox v-model="List_G.wifi_on" :true-label="1" :false-label="0" disabled>wifi打卡</el-checkbox>
                      </div>
                      <div></div>
                    </div>      
                    <div class="Rig_D" style="width:97%"> 
                      <div class="Rig_y" style="float:left;margin-top:4px;margin-bottom:20px">
                        <div style="cursor: pointer;" @click="Tjwei(1)">添加位置</div>
                        <div>可添加多个办公地点</div>
                      </div>
                      <div style="">
                        <el-table ref="multipleTable" :data="LocationList" tooltip-effect="dark" :header-cell-style="{background:'#FAFAFA'}"
                              style="width: 100%" @selection-change="handleSelectionChange" show-overflow-tooltip="false">
                          <el-table-column prop="name" label="位置名称" width="190"></el-table-column>
                          <el-table-column prop="address" label="地址" width="360"></el-table-column>
                          <el-table-column label="操作">
                            <template #default="scope">
                              <el-button size="mini" style="background: #FF524D;margin-left: 10px;color:#fff" disabled @click="handl_Del(1,scope.$index, scope.row)">删除</el-button>
                            </template>
                          </el-table-column>
                        </el-table> 
                      </div>
                      <div class="Rig_y" style="float:left;margin-top:4px;margin:20px 0">
                        <div style="cursor: pointer;" @click="Tjwei(2)">添加Wi-Fi</div>
                        <div>可添加多个办公Wi-Fi</div>
                      </div>                    
                      <div style="">
                        <el-table ref="multipleTable" :data="WifiList" style="width: 100%">
                          <el-table-column prop="ssid" label="wifi名称" width="190"></el-table-column>
                          <el-table-column prop="bssid" label="Mac" width="360"></el-table-column>
                          <el-table-column label="操作">
                            <template #default="scope">
                              <el-button style="background: #FF524D;margin-left: 10px;color:#fff" disabled @click="handl_Del(2, scope.$index, scope.row)">删除</el-button>
                            </template>
                          </el-table-column>
                        </el-table> 
                      </div> 
                    </div> 
                    <div class="Rig_D">
                      <div class="Rig_Ddd" style="float:none">补卡申请： </div>
                      <div style="margin-top:10px;float:left">
                          <el-checkbox v-model="List_G.reissue_on" :true-label="1" :false-label="0" disabled>允许补卡申请</el-checkbox>
                      </div>
                      <div style="float:left;margin-top:12px;font-size: 14px; color: #8C8C8C;margin-left:18px">员工异常打卡时可提交申请，审批通过后修正异常</div>
                    </div>  
                    <div style="overflow:hidden" v-if="List_G.reissue_on == '1'">
                      <div style="width:50%;float:left">
                        <div class="Rig_D">
                          <div class="Rig_Ddd" style="margin-bottom:12px">补卡次数：</div>
                          <div class="Rtty">
                                <el-input v-model="List_G.reissue_num" disabled placeholder="请选择每月最多允许补卡次数" class="El_ii"></el-input>
                          </div>
                        </div> 
                      </div>
                      <div style="width:50%;float:left">
                        <div class="Rig_D">
                          <div class="Rig_Ddd" style="margin-bottom:12px">补卡有效期：</div>
                          <div class="Rtty">
                                <el-input v-model="List_G.reissue_time" disabled placeholder="请选择补卡有效期" class="El_ii"></el-input>
                          </div>
                        </div> 
                      </div>
                    </div>
                    <div class="Rig_D">
                      <div class="Rig_Ddd" style="float:none">更多设置 </div>
                      <div style="margin-top:10px">
                        <el-checkbox v-model="List_G.outside_on" label="允许外勤打卡" :true-label=1 :false-label=0 disabled></el-checkbox>
                        <el-checkbox v-model="List_G.outside_photo_on" label="外勤打卡需拍照" :true-label="1" :false-label="0" disabled></el-checkbox>
                        <el-checkbox v-model="List_G.photo_on" label="开启拍照打卡" :true-label="1" :false-label="0" disabled></el-checkbox>
                        <el-checkbox v-model="List_G.photo_album_on" label="不允许使用相册照片" :true-label="1" :false-label="0" disabled></el-checkbox>
                      </div>
                    </div>  
                    <div class="BCSZ" style="background:#fff;color:#595959;border: 1px solid #D9D9D9;">取消</div>
                </div>
              </div>
            </el-scrollbar>
          </div>
    </div>    
    <!-- 编辑弹出框 -->
    <el-dialog title="添加位置" v-model="editVisible" width="38%">
      <el-form ref="form" :model="form" >
        <div class="home">
          <div id="container"></div>
        </div>
        <el-form-item label="当前位置名称：" style="margin-top:20px">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <div style="overflow:hidden">
          <el-form-item style="width:46%;float:left;margin-right:50px" label="当前经纬度：">
            <el-input v-model="form.lngat"></el-input>
          </el-form-item>
          <el-form-item style="width:46%;float:left" label="打卡距离：">
            <el-input v-model="form.distance"></el-input>
          </el-form-item>
        </div>

      </el-form>
      <template #footer>
        <span class="dialog-footer" style="margin-bottom:20px">
          <el-button @click="editVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveEdit">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog title="添加Wifi" v-model="editVisible2" width="27%">
      <el-form ref="form" label-width="100px">
        <el-form-item label="WIFI名称">
          <el-input v-model="Fror.ssid"></el-input>
        </el-form-item>
        <el-form-item label="MAC地址">
          <el-input v-model="Fror.bssid"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer" style="margin-bottom:20px">
          <el-button @click="editVisible2 = false">取 消</el-button>
          <el-button type="primary" @click="saveEdit2">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template> 

<script>
import axios from 'axios'
import  TextMoth  from "../../components/utils.vue";
import AMapLoader from '@amap/amap-jsapi-loader'
	window._AMapSecurityConfig = {
  		securityJsCode: '0e421469d1396f611c38c84171bede50'
	}
import { fetchData } from "../../api/index";
export default {
  name: "Home",
  data() {
    return {
      map: null,
      markers: [],
      markersPosition: [],
      geocoder: null,
      map:null, // 地图
      maker: null, // 点
      marker: [118.118174,24.468552], // 中心点
      icon: null, // 点的样式
      show11:false,
      input_seach:'',
      activeIndex: "1",  
      Fror: {
        ssid:'',   
        bssid:'', 
      },
      radio:'1',
      show1:false,
      input: "",
      query: {
          address: "",
          name: "",
          pageIndex: 1,
          pageSize: 10
      },
      tableData: [
          {
              id:'01',
              name:'豆腐网销售考勤小队',
              people:'汪汪队',
              banci:'固定上下班',
              time:'周六、周日休息周一、周二、周三、周四、周五班次默认班次: 09:00-18:00',
              num:15,
              fangs:'wifi打卡'

          }
      ],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      editVisible2: false,
      pageTotal: 0,
      form: {
        name: "", //位置名称
        address: "", //详细地址
        latitude: "", //维度
        longitude: "", //经度
        distance: "100" //打卡距离（米）
      },
      idx: -1,
      id: -1,
      Handlbchak_id:0,
      WifiList:[],  
      List_G: {
        name:'',                //名称
        leader_id:'',           //考勤负责人
        department_ids:'',      //考勤部门
        staff_ids:'',           //考勤员工
        type:'fixed',                //考勤类型:fixed=固定班制,class=排班制,free=自由工时
        holiday_on:'1',          //节假日休息  0关闭 1开启
        location_on:'1',          //位置打卡: 1=启用,0=关闭
        location_ids:[],        //位置ID，多选（用,分割）
        wifi_on:[],             //WIFIID，多选（用,分割）
        outside_on:'1',         //外勤打卡
        outside_photo_on:'0',   //外勤拍照打卡:1=启用,0=关闭
        photo_on:'1',           //拍照打卡:1=启用,0=关闭 
        photo_album_on:'1',     //拍照不允许使用相册:1=启用,0=关闭
        reissue_on:'1',          //允许补卡:1=启用,0=关闭
        reissue_num:'1',        //每月允许补卡次数
        reissue_time:'1',       //补卡有效期限（天）

      },
      LocationList:[],
      StaFFList:[],      //全部员工列表
      DMent:[],
      List_ids:[],
      Litaffids:[],
      LocationList2:[],
      XX:0 ,                      //1新建  2编辑       

    };
  },
  mounted() {
    this.getData();

    var that = this
      axios({
        method: 'post',
        url: '/admin/attendance/group/index', 
        data: {
          page: 1,
          offset:0,
          limit:10,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total1 = res.data.data.total
      })   
    axios.get('/admin/ajax/staffList',{                   // 全部员工列表
        }).then(function (res) {
          if (res.data.code == 0) {
            alert('请求失败');  
          } else{ 
            console.log(res.data.data)
              that.StaFFList = res.data.data
          }
    })   
    axios.get('/admin/company/department/index',{
      }).then(function (res) {
        if (res.code == 0) {
          alert('请求失败');  
        } else{ 
          that.DMent = res.data.data.rows
        }
    })
  },
  methods: {
    TextMoMo(e) {
      var arr = TextMoth.getData(e)
      return arr  
    },
    handl_Del(e,index, data) {
      var that = this
      if (e == 1) {
        var LocationList = that.LocationList
        LocationList.splice(index,1)
      } else if (e == 2) {
        var LnList = that.WifiList
        LnList.splice(index,1)
      }
    },
    saveEdit2(){                       //确认添加位置
      var that = this
      var arr = that.WifiList
        axios({
          method: 'post',
          url: '/admin/attendance/wifi/add',
          data: {
            row: that.Fror
          },
        }).then(function (res) {
            console.log()
            arr.push(res.data.data)
            that.WifiList = arr
        }) 
      that.editVisible2 = false
    },
    Tjwei(e) {                         //添加位置
      var that = this
      if (e == 1) {
        that.editVisible = true
            this.initMap();
      } else if (e ==2) {
        that.editVisible2 = true
      }
      
    },
    Xinjian() {
      var that = this
      that.XX = 1
      that.show1 = true
      that.List_G = {},
      that.List_ids = []
      that.Litaffids = []
    },
    BTN_tj() {                        //考勤组提交 保存设置
      var that = this
      that.List_G.id = null
      that.List_G.department_ids = that.List_ids
      that.List_G.staff_ids = that.Litaffids

      var aee = that.WifiList
      var yii = []
      for (let i = 0; i < aee.length; i++) {
        const ele = aee[i];
        yii.push(ele.id)
      }
      that.List_G.wifi_ids = yii

      var aee2 = that.LocationList
      var yii2 = []
      for (let i = 0; i < aee2.length; i++) {
        const ele = aee2[i];
        yii2.push(ele.id)
      }
      that.List_G.location_ids = yii2
      console.log(that.List_G)

        // axios({
        //   method: 'post',
        //   url: '/admin/attendance/group/add',
        //   data: {
        //     row: that.List_G
        //   },
        // }).then(function (res) {
        //     if (res.data.code == 0) {
        //       that.$message.warning(res.data.msg);
        //     } else {
        //        that.$message.success(res.data.msg);
        //         that.show1 = false
        //         axios({
        //           method: 'post',
        //           url: '/admin/attendance/group/index', 
        //           data: {
        //             page: 1,
        //             offset:0,
        //             limit:10,
        //           },
        //         }).then(function (res) {
        //           that.tableData = res.data.data.rows
        //           that.total1 = res.data.data.total
        //         })   

        //     }

        // }) 
    },
    BTN_tj2() {                        //考勤组提交 编辑
      var that = this
      that.List_G.id = null
      that.List_G.department_ids = that.List_ids
      that.List_G.staff_ids = that.Litaffids

      var aee = that.WifiList
      var yii = []
      for (let i = 0; i < aee.length; i++) {
        const ele = aee[i];
        yii.push(parseInt(ele.id))
      }
      that.List_G.wifi_ids = yii

      var aee2 = that.LocationList
      var yii2 = []
      for (let i = 0; i < aee2.length; i++) {
        const ele = aee2[i];
        yii2.push(parseInt(ele.id))
      }
      that.List_G.location_ids = yii2
      console.log(that.LocationList)
      console.log(that.List_G)
        axios({
          method: 'put',
          url: '/admin/attendance/group/edit',
          data: {
            id: that.Handlbchak_id,
            row: that.List_G
          },
        }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
               that.$message.success(res.data.msg);
                that.show1 = false
                axios({
                  method: 'post',
                  url: '/admin/attendance/group/index', 
                  data: {
                    page: 1,
                    offset:0,
                    limit:10,
                  },
                }).then(function (res) {
                  that.tableData = res.data.data.rows
                  that.total1 = res.data.data.total
                })   

            }

        }) 
    },
    
    handhange1(val) {
      var that = this
        axios({
          method: 'post',
          url: '/admin/attendance/group/index', 
          data: {
            page: val,
            offset:0,
            limit:10,
            filter: {
              keyword: that.input_seach
            }
          },
        }).then(function (res) {
          that.tableData = res.data.data.rows
          that.total1 = res.data.data.total
        })   
    },
    Close() {
      this.show1 = false
      this.show11 = false
    },
    Handlbchak(e,data) {
      var that = this
      that.Handlbchak_id = data.id
      console.log(data.id)
      if (e == 1) {
        that.XX = 2
        that.show1 = true
      } else if (e == 2) {
        that.show11 = true
      }
      axios.get('/admin/attendance/group/read',{   // 模块
        params:{
            id:data.id
        }
        }).then(function (res) {
            that.WifiList = res.data.data.wifiList
            that.List_G = res.data.data.row
            that.LocationList = res.data.data.locationList
            that.LocationList2 = res.data.data.locationList.splice()

            var arr = res.data.data.row.department_ids
            var crr = res.data.data.row.staff_ids
            console.log(crr)
            var Ner_arr = []
            var Ner_crr = []
            for (let i = 0; i < arr.length; i++) {
              Ner_arr[i]  =  parseInt(arr[i]);
            }
            for (let i = 0; i < crr.length; i++) {
              Ner_crr[i]  =  parseInt(crr[i]);
              console.log(Ner_crr)
            }
            that.List_ids = Ner_arr
            that.Litaffids = Ner_crr
            console.log(that.LocationList)
      })
    },
    handleSelect(e) {
      if (e == 1) {
        this.$router.push('/Manage/Attend');
      }else if (e == 2) {
        this.$router.push('/Manage/Attend_2');
      } else if (e == 3) {
        this.$router.push('/Manage/Attend_3');
      }
    },
    getData() {
        fetchData(this.query).then(res => {
            console.log(res);
            this.tableData = res.list;
            this.pageTotal = res.pageTotal || 50;
        });
    },
    // 触发搜索按钮
    inpu_sech() {
        var that = this
        axios({
          method: 'post',
          url: '/admin/attendance/group/index', 
          data: {
            page: 1,
            offset:0,
            limit:10,
            filter: {
              keyword: that.input_seach
            }
          },
        }).then(function (res) {
          that.tableData = res.data.data.rows
          that.total1 = res.data.data.total
        })          
    },
    // 删除操作
    HanDel(e) {
        // 二次确认删除
        var that = this
        var arr = []
        arr.push(e.id)
        that.$confirm("确定要删除吗？", "提示", {
            type: "warning"
        }).then(() => {
            axios.delete('/admin/attendance/group/del',{ 
              params:{
                    ids: arr
              }
            }).then(function (res) {
            if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
            } else{ 
                that.$message.success(res.data.msg);
                axios({
                  method: 'post',
                  url: '/admin/attendance/group/index', 
                  data: {
                    page: 1,
                    offset:0,
                    limit:10,
                  },
                }).then(function (res) {
                  that.tableData = res.data.data.rows
                  that.total1 = res.data.data.total
                })   
              }
          })   
        })
    },
    // 多选操作
    handleSelectionChange(val) {
        this.multipleSelection = val;
    },
    // 编辑操作
    handleEdit(index, row) {
        this.idx = index;
        this.form = row;
        this.editVisible = true;
    },
    // 保存编辑
    saveEdit() {
      var that = this
        that.editVisible = false;

        axios({
          method: 'post',
          url: '/admin/attendance/location/add',
          data: {
            row: that.form
          },
        }).then(function (res) {
            console.log(res)
            console.log(that.form)

            var ob1 = {
              id:res.data.data.id,
              group_id:0,
              title:that.form.name,
              name:that.form.name,
              address:that.form.address
            }
            that.LocationList.push(ob1)
        })         
    },
    // 分页导航
    handlePageChange(val) {
        this.$set(this.query, "pageIndex", val);
        this.getData();
    },
    initMap() {
      AMapLoader.load({
        key: "3d62b06249da3d2faabfb630eb3916b0", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.Geocoder"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {

          window.AMap = AMap;
          this.map = new AMap.Map("container", {
            //设置地图容器id
            viewMode: "3D", //是否为3D地图模式
            zoom: 11, //初始化地图级别
            center: [118.771409, 32.067939], //初始化地图中心点位置
          });
                    // 设置点样式
          this.icon = new AMap.Icon({
              image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',  // Icon的图像
              offset: new AMap.Pixel(-13, -30)
          });
          let geocoder
          AMap.plugin('AMap.Geocoder',function(){
            geocoder = new AMap.Geocoder()
          })
          this.geocoder = geocoder
          var that= this
          that.map.on('click', function (e) {

            var lnglatXY = [e.lnglat.getLng(), e.lnglat.getLat()];

            that.openPop(lnglatXY)

          });

          // this.map.on('click',this.openPop); // 给点标记添加点击事件，写入对应函数
          
          // this.map.add(this.maker); // 添加点标记
                    console.log(this.geocoder)
        })
        .catch((e) => {
          console.log(e);
        });
    },

    openPop(e) {
        var that = this
        that.markersPosition = e
        that.form.lngat = e[0] + ',' + e[1]
        that.form.latitude = e[0]
        that.form.longitude = e[1]
        that.removeMarker()
        // 设置新的标记
        that.setMapMarker()
        console.log(e)
        AMap.plugin('AMap.Geocoder', function () {
          var geocoder = new AMap.Geocoder({
            radius: 1000,
            extensions: "all"
          });
          console.log(geocoder)
          geocoder.getAddress(e, function (status, result) {
            if (status === 'complete' && result.info === 'OK') {
              var address = result.regeocode.formattedAddress;
              // that.address = address  //兑换地址
              console.log(result)
              that.form.name = result.regeocode.pois[0].name  
              that.form.address = result.regeocode.formattedAddress
              // that.$emit('getaddress', that.address, e)
            } else {
              // ElMessage.error('无地址，请重新选择');
              console.log(status)
            }
          });
          // 清除所有标记
          that.map.clearMap();
          var marker = new AMap.Marker();
          that.map.add(marker);
          marker.setPosition(e);
        })

    },
    // 设置点击位置的标记
    setMapMarker () {
      let marker = new AMap.Marker({
        map: this.map,
        position: this.markersPosition
      })
      // 将 markers 添加到地图
      this.markers.push(marker)
    },
    // 添加标记
    addMarker () {
      //   经度 纬度
      let lng = Math.random() * 135.05 + 73.3
      let lat = Math.random() * 53.33 + 3.51
      console.log('添加标记', [lng, lat])
      // 添加标记
      this.map.setFitView()

      let marker = new AMap.Marker({
        map: this.map,
        position: [lng, lat]
        // content: markerContent
        // eslint-disable-next-line
                // offset: new AMap.Pixel(-13, -30)
      })
      // 将 markers 添加到地图
      this.markers.push(marker)

      this.map.setFitView()`在这里插入代码片`

      // 鼠标点击marker弹出自定义的信息窗体
      // eslint-disable-next-line no-undef
      AMap.event.addListener(marker, 'click', function (e) {
        console.log('点击marker', e)
      })
    },
    // 删除之前后的标记点
    removeMarker () {
      if (this.markers) {
        this.map.remove(this.markers)
      }
    }
  },
};
</script>

<style scoped>

.el-form-item {
  margin-bottom: 10px;
}
.Tab_T{
  background: #0081ff;
  color: #ffffff;
}
.Attend .Riot {
  overflow: hidden;
  clear: both;
  margin-top: 20px;
}
.Attend .Riot div {
  float: left;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #595959;
}
.Attend .Rig_y div:last-child {
  font-size: 14px;
   
  font-weight: 400;
  color: #8C8C8C;
  margin-left: 14px;
  margin-top: 8px;
}
.Attend .Rig_y div:first-child{
  width: 80px;
  height: 32px;
  background: #FFFFFF;
  border: 1px solid #0081FF;
  border-radius: 3px;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
   
  font-weight: 400;
  color: #0081FF;
}
.Attend .Rig_y div {
  float: left;
}
.Attend .Fiooi .el-input, .Attend .Fiooi .el-input__inner {
  width: 704px;
}
#container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 400px;
}
.input-card {
  top: 1rem;
  left: 1rem;
  height: 13.0833rem;
}

</style>
